<template>
    <div>
        <div class="container-fluid">
            <div class="row d-flex justify-content-center" style="padding-top:10vh; background:#4FB37B;">
                <h1 class="title">Consulta Factura Electronica</h1>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12 py-3 px-2">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4">
                            <div class="row mb-2">
                                <div class="col-sm-3 pr-0 text-right">
                                    <label class="col-form-label">Codigo:</label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                </div>
                                <div class="col-sm-2 text-left">
                                    <button class="btn btn-success" @click="showTable = true">Buscar</button>
                                </div>
                            </div>
                        </div>
                        <div class="colsm-4"></div>
                    </div>
                </div>
                <div class="col-sm-12 py-3 px-2" v-show="showTable">
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-striped table-bordered table-hover" width="100%">
                                <thead>
                                    <tr>
                                        <th width="15%"> Empresa </th>
                                        <th width="15%"> Tipo </th>
                                        <th width="15%"> Fecha </th>
                                        <th width="15%"> N° Factura </th>
                                        <th width="15%"> Importe </th>
                                        <th  width="15%"> Estado </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> Gemglo Soft SA </td>
                                        <td> Computarizada en linea </td>
                                        <td> 31/03/2022 </td>
                                        <td> 3443 </td>
                                        <td> 3.900,00 Bs </td>
                                        <td>
                                            <div style="white-space: nowrap;">
                                                <button class="btn btn-success">Valido</button>
                                                <button class="btn btn-secondary" style="margin-left:10px">Ver Factura</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow-1"></div>
        <div class="app-footer">
            <div class="footer-bottom d-flex flex-column flex-sm-row align-items-center">
                <div class="col-sm-12">
                    <div>
                        <h3 class="text-center" style="color:#4FB37B;">&copy; Gemgloo Soft S.A</h3>
                        <p class="m-0 text-center">Proveedor de Facturacion electronica Ante Impuesto</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "consultarFactura",
    data() {
        return {
            showTable: false,
        };
    },
    methods: {
    }
}
</script>
<style scoped>
.title {
    position: relative;
    top: -50px;
    color: white;
    font-family: cursive;
}
.app-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-family: cursive;
}
</style>